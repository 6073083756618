import React from 'react';
import _ from 'lodash';
import { IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import Barcode from 'react-barcode';
import { Navigate, Button, Logs, Select, Input } from '../components';
import { __, request, getOrderButtons, formatDate, formatAmount, toNumber } from '../functions';
import { BANKS, GATEWAYS } from '../config';
import '../assets/styles/order.css';

/**
 * Order komponenta.
 */
class Order extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        id: 0,
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        showLogs: false,
        data: {},
        settings: {},
        loading: false,
        loadingState: false,
        stateId: 0,
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { id, onClose, setTitle, showSnackbar, changeOrderData } = this.props;

        // Nacitame data objednavky
        request(`/orders/view/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Objednavka neexistuje
                showSnackbar('error', __('Objednávka neexistuje'));
                onClose();
                return;
            }

            // Nastavime title
            setTitle(`${__('Objednávka č.')} ${data.data.number}`);

            // Zmenime data v tabulke
            changeOrderData(id, data.data);

            this.setState({ data: { ...data, invoice: _.has(data, 'invoice') ? data.invoice.data : {} } });
        });
    }

    /**
     * Event po zmene nastaveni.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeSetting(name, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [name]: value } });
    }

    /**
     * Event po zmene klient nastavenia.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeClientSetting(name, value) {
        const { settings } = this.state;

        const client = _.has(settings, 'client') ? settings.client : {};

        this.setState({ settings: { ...settings, client: { ...client, [name]: value } } });
    }

    /**
     * Event po zmene stavu.
     *
     * @param {string} stateId
     */
    async onChangeState(stateId) {
        const { id, changeOrderState, showSnackbar } = this.props;

        this.setState({ loadingState: true });

        await changeOrderState({ showSnackbar }, id, stateId);

        this.setState({ loadingState: false, stateId });
    }

    /**
     * Zavolame akciu.
     *
     * @param {string} name
     */
    callAction(name) {
        const { buttons, onClose, callbackLoading, callback } = this.props;

        // Zavrieme drawer
        onClose();

        // Zavolame callback
        buttons[name].callback(callbackLoading, callback);
    }

    /**
     * Zobrazime logy.
     */
    showLogs() {
        this.setState({ showLogs: true });
    }

    /**
     * Zobrazime objednavky.
     */
    showOrder() {
        this.setState({ showLogs: false });
    }

    /**
     * Ulozenie.
     */
    save() {
        const { id, editOrder, showSnackbar } = this.props;
        const { settings } = this.state;

        if (this.isDemo()) {
            showSnackbar('error', __('Objednávku nie je možné uložiť na DEMO účte'));
            return;
        }

        this.setState({ loading: true });

        // Editujeme objednavku
        editOrder(this, id, settings);
    }

    /**
     * Rendrujeme hodnotu.
     *
     * @param {string} value
     *
     * @return {string}
     */
    renderValue(value) {
        return !_.isEmpty(value) ? value : '-';
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { id, userCouriers, couriers, courierSettings, user } = this.props;
        const { data, showLogs, settings, loading, loadingState, stateId } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return <div
                className="order-content order-content-loading"
            >{this.renderLoading()}</div>;
        }

        // Vytiahneme tlacitka pre objednavku
        const buttons = getOrderButtons(data, {
            settings: this.props.settings,
            hasInvoiceCreate: this.hasAccountingAction('create'),
            hasInvoicePdf: this.hasAccountingAction('pdf'),
            hasInvoicing: this.hasAccountingAction('invoicing'),
            hasPermissionPdfOrder: this.hasPermission('orders-pdf'),
            hasPermissionPdf: this.hasPermission('invoices-pdf'),
            hasPermissionCreateInvoice: this.hasPermission('invoices-create'),
            hasPermissionPaid: this.hasPermission('orders-paid'),
            hasPermissionSend: this.hasPermission('orders-send'),
            hasPermissionDelete: this.hasPermission('orders-delete'),
            userCouriers,
            couriers,
            courierSettings,
            courierSettingsIds: _.reduce(userCouriers, (result, { id }) => ([ ...result, ...[id.toString()] ]), []),
        });

        // Vytiahneme zoznam stavov
        const states = _.reduce(data.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Banky
        const banks = { ...BANKS, ...GATEWAYS };

        // Je odoslane?
        const packageSend = data.package.number !== ''
            && data.package.number !== 'waiting_import'
            && data.package.number !== 'waiting_payment';

        return (
            <div className="order-content">
                <div className="order-content__buttons">
                    {!user.is_seller ? <div className="order-content__buttons__left">
                        {!showLogs ? <Button
                            onClick={() => this.showLogs()}
                            color="shadow"
                            className=""
                        >{__('Zobraziť pohyby')}</Button> : null}
                        {showLogs ? <Button
                            onClick={() => this.showOrder()}
                            color="shadow"
                            className=""
                        >{__('Zobraziť objednávku')}</Button> : null}
                    </div> : null}
                    {window.location.pathname !== '/sellers-orders' ? <div className="order-content__buttons__right">{_.map(buttons, ({ name, icon, callback, options }, key) => {
                        return (
                            <Tooltip title={!options.disabled ? name : ''} key={key}>
                                <IconButton
                                    onClick={() => this.callAction(key)}
                                    disabled={options.disabled}
                                    className={`order-content__buttons__right__button ${options.disabled ? 'disabled' : ''}`}
                                >{icon}</IconButton>
                            </Tooltip>
                        );
                    })}</div> : null}
                </div>
                {!showLogs ? <div className="order-content__panels">
                    {!user.is_seller ? <div className="order-content__panels__panel">
                        <div className="order-content__panels__panel__up">
                            <div className="order-content__panels__panel__up__state">
                                {!loadingState ? <Select
                                    label={__('Stav')}
                                    options={states}
                                    value={!_.isEmpty(stateId) ? stateId : data.data.state.id}
                                    onChange={value => this.onChangeState(value)}
                                    allowEmpty={false}
                                /> : this.renderLoading(30)}
                            </div>
                            <div className="order-content__panels__panel__up__barcode">
                                <Barcode value={data.data.number} displayValue={false} />
                            </div>
                            <Button
                                onClick={() => window.open(`/orders/public/${data.id}/${data.token}`, '_blank')}
                                color="primary"
                                className="order-content__panels__panel__up__url"
                            >{__('Track & Trace')}</Button>
                            <div className="order-content__panels__panel__up__value">
                                <div className="order-content__panels__panel__up__value__label">{__('Vytvorené:')}</div>
                                <div className="order-content__panels__panel__up__value__value">
                                    {this.renderValue(formatDate(data.created, 'dd.mm.yyyy hh:ii'))}
                                </div>
                            </div>
                            <div className="order-content__panels__panel__up__value">
                                <div className="order-content__panels__panel__up__value__label">{__('Platba:')}</div>
                                <div className="order-content__panels__panel__up__value__value">
                                    {this.renderValue(data.data.payment_type_name)}
                                </div>
                            </div>
                            <div className="order-content__panels__panel__up__value">
                                <div className="order-content__panels__panel__up__value__label">{__('Dodanie:')}</div>
                                <div className="order-content__panels__panel__up__value__value">
                                    {this.renderValue(data.data.delivery_type_name)}
                                </div>
                            </div>
                            <div className="order-content__panels__panel__up__value">
                                <div className="order-content__panels__panel__up__value__label">{__('Faktúra:')}</div>
                                <div className="order-content__panels__panel__up__value__value">
                                    {!_.isEmpty(data.invoice) ? data.invoice.number : '-'}
                                </div>
                            </div>
                            <div className="order-content__panels__panel__up__value">
                                <div className="order-content__panels__panel__up__value__label">{__('Uhradené:')}</div>
                                <div className="order-content__panels__panel__up__value__value">
                                    {!_.isEmpty(data.payment) ? `${formatDate(data.payment.created, 'dd.mm.yyyy')} (${_.has(banks, data.payment.data.by)
                                        ? banks[data.payment.data.by]
                                        : __('Manuálne')})` : '-'}
                                </div>
                            </div>
                            <div className="order-content__panels__panel__up__value">
                                <div className="order-content__panels__panel__up__value__label">{__('Číslo zásielky:')}</div>
                                <div className="order-content__panels__panel__up__value__value">
                                    {packageSend ? data.package.number : '-'}
                                </div>
                            </div>
                        </div>
                        <div className="order-content__panels__panel__amount">
                            <span>{formatAmount(data.data.amount, data.data.currency)}</span>
                            <span>{__('Celková suma')}</span>
                        </div>
                    </div> : null}
                    <div className="order-content__panels__panel">
                        {!user.is_seller ? <div className="order-content__panels__panel__client">
                            <div className="order-content__panels__panel__client__panel">
                                <div className="order-content__panels__panel__client__panel__title">{__('Fakturačné údaje')}</div>
                                <Input
                                    label={__('Meno')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'name')
                                        ? settings.client.name
                                        : data.data.client.name}
                                    onChange={value => this.onChangeClientSetting('name', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Ulica')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'address')
                                        ? settings.client.address
                                        : data.data.client.address}
                                    onChange={value => this.onChangeClientSetting('address', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Mesto')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'city')
                                        ? settings.client.city
                                        : data.data.client.city}
                                    onChange={value => this.onChangeClientSetting('city', value)}
                                    disabled
                                />
                                <Input
                                    label={__('PSČ')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'zip')
                                        ? settings.client.zip
                                        : data.data.client.zip}
                                    onChange={value => this.onChangeClientSetting('zip', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Tel. číslo')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'phone')
                                        ? settings.client.phone
                                        : data.data.client.phone}
                                    onChange={value => this.onChangeClientSetting('phone', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Email')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'email')
                                        ? settings.client.email
                                        : data.data.client.email}
                                    onChange={value => this.onChangeClientSetting('email', value)}
                                    disabled
                                />
                            </div>
                            <div className="order-content__panels__panel__client__panel">
                                <div className="order-content__panels__panel__client__panel__title">{__('Dodacie údaje')}</div>
                                <Input
                                    label={__('Meno')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_name')
                                        ? settings.client.delivery_name
                                        : data.data.client.delivery_name}
                                    onChange={value => this.onChangeClientSetting('delivery_name', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Ulica')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_address')
                                        ? settings.client.delivery_address
                                        : data.data.client.delivery_address}
                                    onChange={value => this.onChangeClientSetting('delivery_address', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Mesto')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_city')
                                        ? settings.client.delivery_city
                                        : data.data.client.delivery_city}
                                    onChange={value => this.onChangeClientSetting('delivery_city', value)}
                                    disabled
                                />
                                <Input
                                    label={__('PSČ')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_zip')
                                        ? settings.client.delivery_zip
                                        : data.data.client.delivery_zip}
                                    onChange={value => this.onChangeClientSetting('delivery_zip', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Tel. číslo')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_phone')
                                        ? settings.client.delivery_phone
                                        : data.data.client.delivery_phone}
                                    onChange={value => this.onChangeClientSetting('delivery_phone', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Poznámka')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_note')
                                        ? settings.client.delivery_note
                                        : data.data.client.delivery_note}
                                    onChange={value => this.onChangeClientSetting('delivery_note', value)}
                                    multiline
                                    disabled
                                />
                                <Input
                                    label={__('Interná poznámka')}
                                    value={_.has(settings, 'internal_comment') ? settings.internal_comment : data.data.internal_comment}
                                    onChange={value => this.onChangeSetting('internal_comment', value)}
                                    multiline
                                />
                            </div>
                        </div> : null}
                        <div className="order-content__panels__panel__products">
                            <div className="order-content__panels__panel__products__title">{__('Produkty')}</div>
                            {_.map(data.data.products, (product, key) => {
                                let productData = {};

                                if (_.has(data.products, product.id)) {
                                    productData = {
                                        img: data.products[product.id].img_url,
                                        name: `${data.products[product.id].name}${!_.isEmpty(product.variant) ? ` - ${product.variant}` : ''}`,
                                    };
                                } else {
                                    productData = {
                                        img: '',
                                        name: product.name,
                                    };
                                }

                                const showSku = toNumber(user.user_eshop_id) === 585;
                                const showWeight = toNumber(user.user_eshop_id) === 612;
                                let itemStyle = {};

                                if (showSku) {
                                    itemStyle = { flexDirection: 'column' };
                                }

                                return (
                                    <div className="order-content__panels__panel__products__product" key={key}>
                                        <div className="order-content__panels__panel__products__product__photo">
                                            {!_.isEmpty(productData.img) ? <img src={productData.img} alt={productData.name} /> : <HelpIcon />}
                                        </div>
                                        <div className="order-content__panels__panel__products__product__info">
                                            <div className="order-content__panels__panel__products__product__info__name">{productData.name}</div>
                                            <div className="order-content__panels__panel__products__product__info__values" style={itemStyle}>
                                                <div>{__('Počet')}: {product.quantity}{`${!_.isEmpty(product.unit) ? ` ${product.unit}` : 'x'}`}</div>
                                                <div>{__('Jednotková cena')}: {formatAmount(product.total_price, data.data.currency)}</div>
                                                <div>{__('Celková cena')}: {formatAmount(product.total_price * product.quantity, data.data.currency)}</div>
                                                <div>{__('EAN')}: {this.renderValue(product.ean)}</div>
                                                {showSku ? <div>{__('SKU')}: {this.renderValue(product.number)}</div> : null}
                                                {showWeight ? <div>{__('Váha')}: {product.weight !== '' ? `${formatAmount(product.weight, '')} kg` : '-'}</div> : null}
                                            </div>
                                            {!_.isEmpty(product.meta_data) ? _.map(product.meta_data, (value, name) => {
                                                if (!_.isString(value)) {
                                                    return null;
                                                }

                                                return (
                                                    <div
                                                        className="order-content__panels__panel__products__product__info__meta"
                                                        key={name}
                                                    >
                                                        <div className="order-content__panels__panel__products__product__info__meta__label">
                                                            {name}
                                                        </div>
                                                        <div
                                                            className="order-content__panels__panel__products__product__info__meta__value"
                                                            dangerouslySetInnerHTML={{ __html: value.replace("\n", '<br>') }}
                                                        />
                                                    </div>
                                                );
                                            }) : null}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {!user.is_seller ? <Button
                            onClick={() => this.save()}
                            className="order-content__panels__panel__save"
                            color="green"
                            loading={loading}
                            disabled={_.isEmpty(settings)}
                        >{__('Uložiť')}</Button> : null}
                    </div>
                </div> : <Logs itemType="order" itemId={id} />}
                {this.renderSnackbar()}
            </div>
        );
    }
}

export { Order };
