import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Screen, Button, Input, Select, Message} from '../components';
import { setUser } from '../actions';
import {__, isEmptyString, request} from '../functions';
import '../assets/styles/mtokna_create_excel_scheme.css';

/**
 * Objednavka.
 */
class MtoknaCreateExcelSchemeScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nová excel schéma');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        id: '',
        data: {},
        settings: {
            name: '',
            columns: {},
            map: {},
        },
    };

    options = {
        'sku': __('SKU'),
        'name': __('Názov'),
        'description': __('Popis'),
        'price': __('Cena'),
        'stock': __('Stav na sklade'),
        'weight': __('Váha'),
        'brand': __('Dodávateľ'),
        'image_url': __('Obrázok'),
        'category': __('Kategória'),
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { params, setUser } = this.props;

            const id = params.id !== 'new' ? params.id : '';

            request(`/mtokna/viewExcelScheme/${id}`).then(response => {
                const { status, data, sync } = response.data;

                if (status === 'error') {
                    window.location = '/excel-schemes';
                    return;
                }

                setUser(sync.user);

                let { settings } = this.state;

                settings = { ...settings, ...{
                    columns: data.columns,
                    map: _.reduce(data.columns, (result, item, key) => {
                        return { ...result, [key]: '' };
                    }, {}),
                } };

                if (_.has(data, 'data') && !_.isEmpty(data.data)) {
                    settings = { ...settings, ...{
                        name: data.data.name,
                        columns: data.data.columns,
                        map: data.data.map,
                    } };
                }

                this.setState({
                    id,
                    data,
                    settings,
                });
            });
        }

        return true;
    }

    onChangeSetting(name, value) {
        const { settings } = this.state;

        let additional = {};
        let additional_state = {};

        this.setState({ settings: { ...settings, [name]: value, ...additional }, ...additional_state });
    }

    save() {
        const { params, user } = this.props;
        let { settings } = this.state;

        if (isEmptyString(settings.name)) {
            this.showSnackbar('error', __('Nie je vyplnený názov'));
            return;
        }

        const id = params.id !== 'new' ? params.id : '';

        this.setState({ loading: true });

        if (id !== '') {
            request(`/mtokna/editExcelScheme/${id}`, { data: settings }, 'POST').then(response => {
                const { status } = response.data;

                this.setState({ loading: false });

                if (status === 'error') {
                    this.showSnackbar('error', __('Nepodarilo sa uložiť schému'));
                    return;
                }

                this.showSnackbar('success', __('Schéma bola uložená'));

                setTimeout(() => { window.location = '/excel-schemes' }, 500);
            });

            return;
        }

        request('/mtokna/createExcelScheme', { data: settings }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa uložiť schému'));
                return;
            }

            this.showSnackbar('success', __('Schéma bola uložená'));

            setTimeout(() => { window.location = '/excel-schemes' }, 500);
        });
    }

    onChangeMap(key, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, map: { ...settings.map, [key]: value } } });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { loading, data, id, settings, lightbox } = this.state;
        const { user } = this.props;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        const isEdit = id !== '';

        let allowEdit = true;

        if (user.mtokna_moderator) {
            allowEdit = false;
        }

        return (
            <div className="mtokna_create_excel_scheme">
                <div className="mtokna_create_excel_scheme__header">
                    <div className="mtokna_create_excel_scheme__header__left">
                        <div className="mtokna_create_excel_scheme__header__left__title">
                            {isEdit ? settings.name : __('Nová schéma')}
                        </div>
                    </div>
                </div>
                <div className="mtokna_create_excel_scheme__content">
                    <div className="mtokna_create_excel_scheme__content__sections">
                        <div className="mtokna_create_excel_scheme__content__sections__section">
                            <Input
                                label={__('Názov')}
                                value={settings.name}
                                onChange={value => this.onChangeSetting('name', value)}
                                required
                            />
                        </div>
                        <div className="mtokna_create_excel_scheme__content__sections__section">
                            <Message type="info">{__('Nastavte prvú položku v exceli')}</Message>
                            {_.map(settings.map, (value, key) => {
                                return (
                                    <div className="mtokna_create_excel_scheme__content__sections__section__item" key={key}>
                                        <div className="mtokna_create_excel_scheme__content__sections__section__item__name">
                                            <span>{key}</span>
                                            <span>{_.truncate(settings.columns[key], { length: 64 })}</span>
                                        </div>
                                        <Select
                                            value={value}
                                            options={this.options}
                                            onChange={value => this.onChangeMap(key, value)}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="mtokna_create_excel_scheme__content__button"
                        color="green"
                        disabled={!allowEdit}
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps, {
    setUser,
})(MtoknaCreateExcelSchemeScreen));
