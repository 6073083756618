import _ from 'lodash';
import {
    FETCH_MTOKNA_EXCEL_SCHEMES,
    CLEAN_MTOKNA_EXCEL_SCHEMES,
    CHANGE_MTOKNA_EXCEL_SCHEME,
    DELETE_MTOKNA_EXCEL_SCHEME,
} from './types';
import { request, __ } from '../functions';

export const fetchMtoknaExcelSchemes = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request('/mtokna/excelSchemes', params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_EXCEL_SCHEMES, payload: data });
    });
};

export const changeMtoknaExcelScheme = (screen, id, data) => {
    return dispatch => request(`/mtokna/editExcelScheme/${id}`, { data }, 'POST').then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmeniť schému'));
            return;
        }

        screen.showSnackbar('success', __('Schéma bola zmenená'));

        dispatch({ type: CHANGE_MTOKNA_EXCEL_SCHEME, payload: { id, data } });
    });
};

export const deleteMtoknaExcelScheme = (screen, id) => {
    return dispatch => request(`/mtokna/removeExcelScheme/${id}`).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa zmazať schému'));
            return;
        }

        screen.showSnackbar('success', __('Schéma bola zmazaná'));

        dispatch({ type: DELETE_MTOKNA_EXCEL_SCHEME });
    });
};

export const cleanMtoknaExcelSchemes = () => ({ type: CLEAN_MTOKNA_EXCEL_SCHEMES });
