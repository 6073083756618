import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, Input, Select, Checkbox} from '../components';
import {
    fetchMtoknaOwners,
    cleanMtoknaOwners,
    setUser,
    changeUserSetting,
    changeMtoknaOwnerEmail,
    changeMtoknaOwnerPassword,
    changeMtoknaOwnerSettings,
} from '../actions';
import {__, formatDate, isEmptyString, isValidEmail, request} from '../functions';
import '../assets/styles/mtokna_owners.css';
import EditIcon from "@mui/icons-material/Edit";
import PasswordIcon from "@mui/icons-material/Password";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import {PASSWORD_MIN_LENGTH} from "../config";
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Storefront';

/**
 * Klienti.
 */
class MtoknaOwnersScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Predajcovia VO');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            create: false,
            move: false,
            email: false,
            password: false,
            settings: false,
            eshops: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    default_states = null;

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_owners';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>{__('ID')}</div>,
            <div>{__('E-mail')}</div>,
            <div>{__('Registrácia')}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user, items } = this.props;

        if (this.default_states === null) {
            this.default_states = items.default_state_settings;
        }

        return [
            <div>
                {item.id}
            </div>,
            <div>
                {item._matchingData.Users.email}
            </div>,
            <div>
                {formatDate(item._matchingData.Users.created, 'dd.mm.yyyy hh:ii')}
            </div>,
            <div>
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Presunúť do MO'),
                    <GroupRemoveIcon />,
                    (callbackLoading, callback) => this.confirmMove(item.user_id, callbackLoading, callback),
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Nastavenia'),
                    <SettingsIcon />,
                    () => this.showLightbox('settings', { id: item.user_id, loading: false, settings: item.settings }),
                    {}
                ) : null}
                {!_.isEmpty(item.eshops) && !user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Eshopy'),
                    <DescriptionIcon />,
                    () => {
                        this.showLightbox('eshops', {
                            id: item.user_id,
                            loading: false,
                            settings: item.settings,
                            eshops: _.reduce(item.eshops, (result, item) => {
                                return { ...result, [item.id]: item.name };
                            }, {}),
                            eshop_states: _.reduce(item.eshops, (result, item) => {
                                return { ...result, [item.id]: _.reduce(item.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {}) };
                            }, {}),
                            eshop: item.eshops[0].id
                        });
                    },
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Zmena emailu'),
                    <EditIcon />,
                    (callbackLoading, callback) => this.confirmEmail(item.user_id, callbackLoading, callback, item._matchingData.Users.email),
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Zmena hesla'),
                    <PasswordIcon />,
                    (callbackLoading, callback) => this.confirmPassword(item.user_id, callbackLoading, callback),
                    {}
                ) : null}
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'Users-email':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Email'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    getTags() {
        const { user } = this.props;

        if (user.mtokna_moderator) {
            return [];
        }

        const random = Math.random().toString(36).slice(-8);

        return [
            {
                name: __('Nový VO'),
                onClick: () => this.showLightbox('create', {
                    loading: false,
                    email: '',
                    password: random,
                    password_repeat: random,
                }),
            }
        ];
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadnych predajcov');
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeSettings(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, settings: { ...lightbox.settings, [key]: value } } })
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeSettingsSetting(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, settings: { ...lightbox.settings, settings: { ...lightbox.settings.settings, [key]: value } } } })
    }

    onChangeDisabledEshop(eshop) {
        const { lightbox } = this.state;

        let value = lightbox.settings.settings.disabled_eshops;

        if (!_.has(value, eshop)) {
            value = { ...value, [eshop]: 1 };
        } else {
            value = _.omit(value, [eshop]);
        }

        this.onChangeSettingsSetting('disabled_eshops', value);
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeEshops(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, eshops: { ...lightbox.eshops, [key]: value } } })
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeEshopsSetting(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, eshops: { ...lightbox.eshops, settings: { ...lightbox.eshops.settings, [key]: value } } } })
    }

    onChangeEshopsState(eshop_id, key, v) {
        const { lightbox } = this.state;

        let value = lightbox.eshops.settings.states;

        if (_.has(value, eshop_id)) {
            value = { ...value, [eshop_id]: { ...value[eshop_id], [key]: v } };
        }

        this.onChangeEshopsSetting('states', value);
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeEmail(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, email: { ...lightbox.email, email: value } } })
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} value
     */
    onChangePassword(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, password: { ...lightbox.password, password: value } } })
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} value
     */
    onChangePasswordRepeat(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, password: { ...lightbox.password, password_repeat: value } } })
    }

    /**
     * Event po zmene create.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeCreate(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, [key]: value } } })
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {string} email
     */
    confirmEmail(id, callbackLoading, callback, email) {
        this.showLightbox('email', { id, callbackLoading, callback, email });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmPassword(id, callbackLoading, callback) {
        const password = Math.random().toString(36).slice(-8);

        this.showLightbox('password', { id, callbackLoading, callback, password, password_repeat: password });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmMove(id, callbackLoading, callback) {
        this.showLightbox('move', { id, callbackLoading, callback });
    }

    /**
     * Ulozenie emailu.
     */
    async changeEmail() {
        const { changeMtoknaOwnerEmail } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.email.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('email');

        // Zmenime email
        await changeMtoknaOwnerEmail(this, lightbox.email.id, lightbox.email.email);

        // Zavolame callback
        lightbox.email.callback();
    }

    /**
     * Ulozenie hesla.
     */
    async changePassword() {
        const { changeMtoknaOwnerPassword } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.password.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('password');

        // Zmenime heslo
        await changeMtoknaOwnerPassword(this, lightbox.password.id, lightbox.password.password, lightbox.password.password_repeat);

        // Zavolame callback
        lightbox.password.callback();
    }

    /**
     * Ulozenie hesla.
     */
    async move() {
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.move.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('move');

        await request(`/mtokna/changeUserToSeller/${lightbox.move.id}`).then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa zmeniť predajcu'));
                return;
            }

            // Refreshneme
            window.location = window.location.pathname;
        });

        // Zavolame callback
        lightbox.move.callback();
    }

    /**
     * Ulozenie hesla.
     */
    saveSettings() {
        const { changeMtoknaOwnerSettings } = this.props;
        const { lightbox } = this.state;

        const settings = { ...lightbox.settings.settings, states: _.reduce(lightbox.settings.settings.states, (result, states, eshop_id) => {
            return { ...result, [eshop_id]: _.reduce(states, (result, states, state) => {
                return { ...result, [state]: _.split(states, '===') };
            }, {}) };
        }, {}) };

        this.onChangeSettings('loading', true);

        changeMtoknaOwnerSettings(this, lightbox.settings.id, settings, 'settings', lightbox.settings.settings);
    }

    saveEshops() {
        const { changeMtoknaOwnerSettings } = this.props;
        const { lightbox } = this.state;

        const settings = { ...lightbox.eshops.settings, states: _.reduce(lightbox.eshops.settings.states, (result, states, eshop_id) => {
            return { ...result, [eshop_id]: _.reduce(states, (result, states, state) => {
                return { ...result, [state]: _.split(states, '===') };
            }, {}) };
        }, {}) };

        this.onChangeEshops('loading', true);

        changeMtoknaOwnerSettings(this, lightbox.eshops.id, settings, 'eshops', lightbox.eshops.settings);
    }

    create() {
        const { lightbox } = this.state;

        if (!isValidEmail(lightbox.create.email)) {
            // Nie je vyplneny email
            this.showSnackbar('error', __('Neplatný email'));
            return;
        } else if (lightbox.create.password.length < PASSWORD_MIN_LENGTH) {
            // Nie je vyplnene heslo
            this.showSnackbar(
                'error',
                __('Minimálna dĺžka hesla je {COUNT} znakov').replace('{COUNT}', PASSWORD_MIN_LENGTH.toString())
            );
            return;
        } else if (lightbox.create.password !== lightbox.create.password_repeat) {
            // Heslo a heslo znovu sa nerovnaju
            this.showSnackbar('error', __('Heslá nie sú rovnaké'));
            return;
        }

        this.onChangeCreate('loading', true);

        const register_data = {
            email: lightbox.create.email,
            password: lightbox.create.password,
            password_repeat: lightbox.create.password_repeat,
        };

        request('/mtokna/register', register_data, 'POST').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.onChangeCreate('loading', false);
                this.showSnackbar('error', __('Email sa už používa'));
                return;
            }

            // Refreshneme
            window.location = window.location.pathname;
        });
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { items } = this.props;
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'move',
                    __('Naozaj chcete presunúť tohto predajcu do MO?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.move()
                )}
                {this.renderLightbox(
                    'email',
                    __('Zmena emailu'),
                    !_.isEmpty(lightbox.email) ? <div>
                        <Input
                            label={__('Email')}
                            value={lightbox.email.email}
                            onChange={value => this.onChangeEmail(value)}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.changeEmail()
                )}
                {this.renderLightbox(
                    'password',
                    __('Zmena hesla'),
                    !_.isEmpty(lightbox.password) ? <div>
                        <div>
                            <Input
                                label={__('Heslo')}
                                value={lightbox.password.password}
                                onChange={value => this.onChangePassword(value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Heslo znovu')}
                                value={lightbox.password.password_repeat}
                                onChange={value => this.onChangePasswordRepeat(value)}
                            />
                        </div>
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.changePassword()
                )}
                {this.renderLightbox(
                    'create',
                    __('Nový VO'),
                    !_.isEmpty(lightbox.create) ? (lightbox.create.loading ? this.renderLoading(20) : <div>
                        <div>
                            <Input
                                label={__('Email')}
                                value={lightbox.create.email}
                                onChange={value => this.onChangeCreate('email', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Heslo')}
                                value={lightbox.create.password}
                                onChange={value => this.onChangeCreate('password', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Heslo znovu')}
                                value={lightbox.create.password_repeat}
                                onChange={value => this.onChangeCreate('password_repeat', value)}
                            />
                        </div>
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.create()
                )}
                {this.renderLightbox(
                    'settings',
                    __('Nastavenia'),
                    !_.isEmpty(lightbox.settings) ? (lightbox.settings.loading ? this.renderLoading(20) : <div>
                        <Checkbox
                            label={__('Zakázať WooCommerce')}
                            value={_.has(lightbox.settings.settings.disabled_eshops, 'WooCommerce') && lightbox.settings.settings.disabled_eshops['WooCommerce']}
                            onChange={checked => this.onChangeDisabledEshop('WooCommerce')}
                        />
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Produkty - Názov hlavnej kategórie')}
                                value={lightbox.settings.settings.category_name}
                                onChange={value => this.onChangeSettingsSetting('category_name', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Produkty - SKU prefix')}
                                value={lightbox.settings.settings.sku_prefix}
                                onChange={value => this.onChangeSettingsSetting('sku_prefix', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Produkty - Marža %')}
                                value={lightbox.settings.settings.margin}
                                onChange={value => this.onChangeSettingsSetting('margin', value)}
                            />
                        </div>
                        {!_.isEmpty(items.schemes) ? <div style={{ marginTop: '20px' }}>
                            <Select
                                label={__('XML schéma')}
                                value={lightbox.settings.settings.scheme}
                                options={items.schemes}
                                onChange={value => this.onChangeSettingsSetting('scheme', value)}
                            />
                        </div> : null}
                        {!_.isEmpty(items.excel_schemes) ? <div style={{ marginTop: '20px' }}>
                            <Select
                                label={__('Excel schéma')}
                                value={lightbox.settings.settings.excel_scheme}
                                options={items.excel_schemes}
                                onChange={value => this.onChangeSettingsSetting('excel_scheme', value)}
                            />
                        </div> : null}
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveSettings()
                )}
                {this.renderLightbox(
                    'eshops',
                    __('Eshopy'),
                    !_.isEmpty(lightbox.eshops) ? (lightbox.eshops.loading ? this.renderLoading(20) : <div>
                        <Select
                            label={__('Eshop')}
                            value={lightbox.eshops.eshop}
                            options={lightbox.eshops.eshops}
                            onChange={value => this.onChangeEshops('eshop', value)}
                            allowEmpty={false}
                        />
                        {_.map(lightbox.eshops.settings.states[lightbox.eshops.eshop], (states, state) => {
                            return (
                                <div key={state} style={{ marginTop: '10px' }}>
                                    <Select
                                        label={state}
                                        value={states}
                                        options={lightbox.eshops.eshop_states[lightbox.eshops.eshop]}
                                        onChange={value => this.onChangeEshopsState(lightbox.eshops.eshop, state, value)}
                                        multiple={true}
                                    />
                                </div>
                            );
                        })}
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveEshops()
                )}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_owners, user }) => ({ items: mtokna_owners, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaOwners,
    clean: cleanMtoknaOwners,
    setUser,
    changeUserSetting,
    changeMtoknaOwnerEmail,
    changeMtoknaOwnerPassword,
    changeMtoknaOwnerSettings,
})(MtoknaOwnersScreen));
