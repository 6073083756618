import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, Input, Select} from '../components';
import {
    fetchRainproPickers,
    cleanRainproPickers,
    setUser,
    changeUserSetting,
    changeRainproPickerEmail,
    changeRainproPickerPassword,
    changeRainproPickerSettings,
} from '../actions';
import {__, formatDate, isValidEmail, request} from '../functions';
import '../assets/styles/rainpro_pickers.css';
import EditIcon from "@mui/icons-material/Edit";
import PasswordIcon from "@mui/icons-material/Password";
import {PASSWORD_MIN_LENGTH} from "../config";
import SettingsIcon from '@mui/icons-material/Settings';

/**
 * Klienti.
 */
class RainproPickersScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Skladníci');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            create: false,
            email: false,
            password: false,
            settings: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    default_states = null;

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'rainpro_pickers';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>{__('ID')}</div>,
            <div>{__('E-mail')}</div>,
            <div>{__('Registrácia')}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        return [
            <div>
                {item.id}
            </div>,
            <div>
                {item._matchingData.Users.email}
            </div>,
            <div>
                {formatDate(item._matchingData.Users.created, 'dd.mm.yyyy hh:ii')}
            </div>,
            <div>
                {this.renderTableButton(
                    item.id,
                    __('Nastavenia'),
                    <SettingsIcon />,
                    () => this.showLightbox('settings', { id: item.user_id, loading: false, settings: item.settings }),
                    {}
                )}
                {this.renderTableButton(
                    item.id,
                    __('Zmena emailu'),
                    <EditIcon />,
                    (callbackLoading, callback) => this.confirmEmail(item.user_id, callbackLoading, callback, item._matchingData.Users.email),
                    {}
                )}
                {this.renderTableButton(
                    item.id,
                    __('Zmena hesla'),
                    <PasswordIcon />,
                    (callbackLoading, callback) => this.confirmPassword(item.user_id, callbackLoading, callback),
                    {}
                )}
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'Users-email':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Email'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    getTags() {
        const { user } = this.props;

        const random = Math.random().toString(36).slice(-8);

        return [
            {
                name: __('Nový skladník'),
                onClick: () => this.showLightbox('create', {
                    loading: false,
                    email: '',
                    password: random,
                    password_repeat: random,
                }),
            }
        ];
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadnych skladníkov');
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeSettings(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, settings: { ...lightbox.settings, [key]: value } } })
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeSettingsSetting(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, settings: { ...lightbox.settings, settings: { ...lightbox.settings.settings, [key]: value } } } })
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} value
     */
    onChangeEmail(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, email: { ...lightbox.email, email: value } } })
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} value
     */
    onChangePassword(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, password: { ...lightbox.password, password: value } } })
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} value
     */
    onChangePasswordRepeat(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, password: { ...lightbox.password, password_repeat: value } } })
    }

    /**
     * Event po zmene create.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeCreate(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, [key]: value } } })
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {string} email
     */
    confirmEmail(id, callbackLoading, callback, email) {
        this.showLightbox('email', { id, callbackLoading, callback, email });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmPassword(id, callbackLoading, callback) {
        const password = Math.random().toString(36).slice(-8);

        this.showLightbox('password', { id, callbackLoading, callback, password, password_repeat: password });
    }

    /**
     * Ulozenie emailu.
     */
    async changeEmail() {
        const { changeRainproPickerEmail } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.email.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('email');

        // Zmenime email
        await changeRainproPickerEmail(this, lightbox.email.id, lightbox.email.email);

        // Zavolame callback
        lightbox.email.callback();
    }

    /**
     * Ulozenie hesla.
     */
    async changePassword() {
        const { changeRainproPickerPassword } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.password.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('password');

        // Zmenime heslo
        await changeRainproPickerPassword(this, lightbox.password.id, lightbox.password.password, lightbox.password.password_repeat);

        // Zavolame callback
        lightbox.password.callback();
    }

    /**
     * Ulozenie hesla.
     */
    saveSettings() {
        const { changeRainproPickerSettings } = this.props;
        const { lightbox } = this.state;

        const settings = lightbox.settings.settings;

        this.onChangeSettings('loading', true);

        changeRainproPickerSettings(this, lightbox.settings.id, settings, 'settings', lightbox.settings.settings);
    }

    create() {
        const { lightbox } = this.state;

        if (!isValidEmail(lightbox.create.email)) {
            // Nie je vyplneny email
            this.showSnackbar('error', __('Neplatný email'));
            return;
        } else if (lightbox.create.password.length < PASSWORD_MIN_LENGTH) {
            // Nie je vyplnene heslo
            this.showSnackbar(
                'error',
                __('Minimálna dĺžka hesla je {COUNT} znakov').replace('{COUNT}', PASSWORD_MIN_LENGTH.toString())
            );
            return;
        } else if (lightbox.create.password !== lightbox.create.password_repeat) {
            // Heslo a heslo znovu sa nerovnaju
            this.showSnackbar('error', __('Heslá nie sú rovnaké'));
            return;
        }

        this.onChangeCreate('loading', true);

        const register_data = {
            email: lightbox.create.email,
            password: lightbox.create.password,
            password_repeat: lightbox.create.password_repeat,
        };

        request('/rainpro/register', register_data, 'POST').then(response => {
            const { status } = response.data;

            if (status === 'error') {
                this.onChangeCreate('loading', false);
                this.showSnackbar('error', __('Email sa už používa'));
                return;
            }

            // Refreshneme
            window.location = window.location.pathname;
        });
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { items } = this.props;
        const { lightbox } = this.state;

        const states = _.reduce(items.states, (result, item) => ({ ...result, [item.id]: item.name }), {});

        return (
            <div>
                {this.renderLightbox(
                    'email',
                    __('Zmena emailu'),
                    !_.isEmpty(lightbox.email) ? <div>
                        <Input
                            label={__('Email')}
                            value={lightbox.email.email}
                            onChange={value => this.onChangeEmail(value)}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.changeEmail()
                )}
                {this.renderLightbox(
                    'password',
                    __('Zmena hesla'),
                    !_.isEmpty(lightbox.password) ? <div>
                        <div>
                            <Input
                                label={__('Heslo')}
                                value={lightbox.password.password}
                                onChange={value => this.onChangePassword(value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Heslo znovu')}
                                value={lightbox.password.password_repeat}
                                onChange={value => this.onChangePasswordRepeat(value)}
                            />
                        </div>
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.changePassword()
                )}
                {this.renderLightbox(
                    'create',
                    __('Nový skladník'),
                    !_.isEmpty(lightbox.create) ? (lightbox.create.loading ? this.renderLoading(20) : <div>
                        <div>
                            <Input
                                label={__('Email')}
                                value={lightbox.create.email}
                                onChange={value => this.onChangeCreate('email', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Heslo')}
                                value={lightbox.create.password}
                                onChange={value => this.onChangeCreate('password', value)}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Heslo znovu')}
                                value={lightbox.create.password_repeat}
                                onChange={value => this.onChangeCreate('password_repeat', value)}
                            />
                        </div>
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.create()
                )}
                {this.renderLightbox(
                    'settings',
                    __('Nastavenia'),
                    !_.isEmpty(lightbox.settings) ? (lightbox.settings.loading ? this.renderLoading(20) : <div>
                        <div style={{ marginTop: '20px' }}>
                            <Input
                                label={__('Produkty - SKU prefix')}
                                value={lightbox.settings.settings.sku_prefix}
                                onChange={value => this.onChangeSettingsSetting('sku_prefix', value)}
                            />
                            <div style={{ marginTop: '20px' }}>
                                <Select
                                    label={__('Stav objednávky po odoslaní')}
                                    value={lightbox.settings.settings.state_after_send}
                                    options={states}
                                    onChange={value => this.onChangeSettingsSetting('state_after_send', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Select
                                    label={__('Stav objednávky po doručení')}
                                    value={lightbox.settings.settings.state_after_delivery}
                                    options={states}
                                    onChange={value => this.onChangeSettingsSetting('state_after_delivery', value)}
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Select
                                    label={__('Prepravcovia')}
                                    value={lightbox.settings.settings.user_couriers}
                                    options={items.user_couriers}
                                    onChange={value => this.onChangeSettingsSetting('user_couriers', value)}
                                    multiple={true}
                                />
                            </div>
                        </div>
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveSettings()
                )}
            </div>
        );
    }
}

const stateToProps = ({ rainpro_pickers, user }) => ({ items: rainpro_pickers, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchRainproPickers,
    clean: cleanRainproPickers,
    setUser,
    changeUserSetting,
    changeRainproPickerEmail,
    changeRainproPickerPassword,
    changeRainproPickerSettings,
})(RainproPickersScreen));
